import * as React from 'react';
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import { Button } from "gatsby-material-ui-components"
import aes from 'js-crypto-aes'
import random from 'js-crypto-random'
import { Buffer } from 'buffer'
import Grid from '@mui/material/Grid'
import GridItem from '../components/GridItem'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

export default function Crypto() {

  const [values, setValues] = React.useState({
    algorithm: 'AES-GCM',
    key: '',
    iv: '',
    keyFieldSize: 0,

    targetPlainText: '',
    outEncryptedText: '',

    targetEncryptedText: '',
    outDecryptedText: ''
  });

  const algorithmSet = {
    "AES-CBC": {
      keySize: [16, 32], //bytes
      ivSize: 16
    },
    "AES-GCM": {
      keySize: [16, 32],
      ivSize: 12
    }
  }
  const handleChangeAlgorithm = (event) => {
    setValues({
      ...values,
      algorithm: event.target.value,
    });
  };

  const handleOnChangeKey = (event) => {
    setValues({
      ...values,
      key: event.target.value,
      keyFieldSize: Buffer.from(event.target.value, 'base64').length * 8
    });
  };

  const handleOnChangeIV = (event) => {
    setValues({
      ...values,
      iv: event.target.value,
      ivFieldSize: Buffer.from(event.target.value, 'base64').length * 8
    });
  };

  const handleOnChangeTargetPlainText = (event) => {
    setValues({
      ...values,
      targetPlainText: event.target.value,
    });
  };

  const handleOnClickRandomKey128Bit = () => {
    const randomBytes = random.getRandomBytes(algorithmSet[values.algorithm].keySize[0]);
    const randomBuffer = Buffer.from(randomBytes);
    setValues({
      ...values,
      key: randomBuffer.toString('base64'),
      keyFieldSize: randomBuffer.length * 8
    });
  };

  const handleOnClickRandomKey256Bit = () => {

    const randomBytes = random.getRandomBytes(algorithmSet[values.algorithm].keySize[1]);
    const randomBuffer = Buffer.from(randomBytes);
    setValues({
      ...values,
      key: randomBuffer.toString('base64'),
      keyFieldSize: randomBuffer.length * 8
    });
  };

  const handleOnClickRandomIV = () => {
    const randomBytes = random.getRandomBytes(algorithmSet[values.algorithm].ivSize);
    const randomBuffer = Buffer.from(randomBytes);

    setValues({
      ...values,
      iv: Buffer.from(randomBytes).toString('base64'),
      ivFieldSize: randomBuffer.length * 8
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const encrypt = () => {
    const msg = Buffer.from(values.targetPlainText, "utf-8");
    const iv = Buffer.from(values.iv, 'base64');
    const key = Buffer.from(values.key, 'base64');
    const algorithm = values.algorithm;
    aes.encrypt(msg, key, { name: algorithm, iv }).then((encrypted) => {
      console.log(Buffer.from(encrypted).toString('base64')); // expected 5I6ge6IaIvfN2Y6r2FGu8A==
      setValues({
        ...values,
        outEncryptedText: Buffer.from(encrypted).toString('base64')
      });
    });
  };

  const decrypt = () => {
    const msg = Buffer.from(values.targetEncryptedText, "base64");
    const iv = Buffer.from(values.iv, 'base64');
    const key = Buffer.from(values.key, 'base64');
    const algorithm = values.algorithm;
    aes.decrypt(msg, key, { name: algorithm, iv }).then((decrypted) => {
      setValues({
        ...values,
        outDecryptedText: Buffer.from(decrypted).toString('utf-8')
      });
    });
  }

  return (
    <Layout>
      <Helmet>
        <title>Online Encryption Tool - AES/GCM, AES/CBC</title>
        <meta name="keywords" content="online,aes,gcm,cbc,encryptor,decryptor" />
        <meta name="description" content="AES/GCM, AES/CBC Online Encryption Tool" />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridItem>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent="center">
              <FormControl>
                <InputLabel id="crypto-algorithm-select-helper-label">Algorithm</InputLabel>
                <Select
                  labelId="crypto-algorithm-select-helper-label"
                  id="crypto-algorithm-select-helper"
                  value={values.algorithm}
                  label="Algorithm"
                  onChange={handleChangeAlgorithm}
                >
                  <MenuItem value={"AES-GCM"}>AES/GCM</MenuItem>
                  <MenuItem value={"AES-CBC"}>AES/CBC</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel htmlFor="component-key">Key(Base64)</InputLabel>
                <OutlinedInput
                  id="component-key"
                  fullWidth={true}
                  value={values.key}
                  onChange={handleOnChangeKey}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle visibility"
                        onClick={handleOnClickRandomKey128Bit}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <ShuffleIcon />
                      </IconButton>
                      <IconButton
                        aria-label="toggle visibility"
                        onClick={handleOnClickRandomKey256Bit}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <ShuffleIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Key(Base64)"
                />
                <FormHelperText>{values.keyFieldSize} bit</FormHelperText>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel htmlFor="component-iv">IV(Base64)</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="component-iv"
                  value={values.iv}
                  onChange={handleOnChangeIV}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle visibility"
                        onClick={handleOnClickRandomIV}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <ShuffleIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="IV(Base64)"
                />
                <FormHelperText>{values.ivFieldSize} bit</FormHelperText>
              </FormControl>
            </Stack>          
          </GridItem>
        </Grid>
        <Grid item xs={6}>
          <GridItem>
            <TextField
              id="textfield-plaintext"
              label="Plain Text(UTF-8)"
              multiline
              rows={4}
              fullWidth
              value={values.targetPlainText}
              onChange={handleOnChangeTargetPlainText}
              sx={{ marginTop: 2 }}
            />
            <Button
              fullWidth variant="contained" size="large"
              onClick={encrypt}
              sx={{ marginTop: 2 }}
            >
              ENCRYPT
            </Button>
            <TextField
              id="textfield-encryptedtext"
              label="Encrypted Base64"
              multiline
              rows={4}
              fullWidth
              value={values.outEncryptedText}
              sx={{ marginTop: 2 }}
            />
          </GridItem>

        </Grid>
        <Grid item xs={6}>
          <GridItem>
            <TextField
              id="textfield-encryptedtext"
              label="Encrypted Base64"
              multiline
              rows={4}
              fullWidth
              onChange={(event) => {
                setValues({
                  ...values,
                  targetEncryptedText: event.target.value,
                });
              }}
              value={values.targetEncryptedText}
              sx={{ marginTop: 2 }}
            />
            <Button fullWidth variant="contained" size="large"
              onClick={decrypt}
              sx={{ marginTop: 2 }}
            >
              DECRYPT
            </Button>
            <TextField
              id="textfield-encryptedtext"
              label="Decrypted Text (UTF-8)"
              multiline
              rows={4}
              fullWidth
              value={values.outDecryptedText}
              sx={{ marginTop: 2 }}
            />
          </GridItem>
        </Grid>
      </Grid>
      <Paper>
        <Typography component="div">
          <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
            <h2>How to use</h2>
            <ol>
              <li>Select Algorithm</li>
              <li>Input Key and IV</li>
              <li>Input Plain Text</li>
              <li>Click Encrypt</li>
              <li>Input Encrypted Text</li>
              <li>Click Decrypt</li>
            </ol>
            <p>Looking for a quick and easy way to encrypt or decrypt your sensitive data? Our online AES encryption/decryption tool has got you covered! With AES/GCM and AES/CBC options, you can choose the encryption mode that best fits your needs. Our tool supports different key sizes and padding options to provide maximum security for your data.
              Whether you need to encrypt a message or a file, our online tool allows you to do it with just a few clicks. No need to install any software or worry about compatibility issues – our tool works on any device with a modern web browser. Plus, all encryption and decryption is done directly in your browser, so your data is never transmitted over the internet.
              Try our AES encryption/decryption tool today and keep your sensitive data safe and secure!</p>
          </Box>
        </Typography>
      </Paper>
    </Layout>
  );
}